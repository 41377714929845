import { navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ClassBanner from "../../components/classes/ClassBanner";
import ClassesSlider from "../../components/classes/ClassesSlider";
import ClassTabs from "../../components/classes/ClassTabs";
import Categories from "../../components/explore/Categories";
import ClassesGrid from "../../components/explore/ClassesGrid";
import Container from "../../components/layout/Container";
import Layout from "../../components/shared/layout";
import SectionTitle from "../../components/ui/SectionHeader";
import VideoPlayer from "../../components/VideoPlayer";
import { useAuth } from "../../context/AuthContext";
import { getClass, useFetch } from "../../services/classes";

const IndexPage = ({ params, category }) => {
	// const category = params["category"];
	const { userData, authModal } = useAuth();


	// const { data: classData, loading: loadingClass, error: classError} = useFetch(`classes?category=${data}`);

	return (
		<Layout transparentNavbar={false} seoTitle="Explore Classes">
			<Container>
				<SectionTitle title="Explore Classes" subtitle="Bringing together some of the sharpest minds, creative artists, and influencers in Korean culture, music." />
				<div className="grid grid-cols-7 gap-4 mb-24">
					<div className="col-span-7 md:col-span-2">
						<Categories activeCategory={category} />
					</div>
					<div className="col-span-7 md:col-span-5">
						<ClassesGrid category={category} />
					</div>
				</div>
			</Container>
		</Layout>
	);
};

export default IndexPage;
