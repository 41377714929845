import React from "react";
import { useFetch } from "../../services/classes";
import { navigate } from 'gatsby';
import ClassTile from "../classes/ClassTile";

export default function ClassesGrid({ category }) {
	
	const { data: classes, loading, error} = useFetch(category ? `classes?category.name=${category}` : `classes`);

	return (
		<div className="grid grid-cols-2 md:grid-cols-3 gap-5">
			{classes &&
				classes.map((item, index) => (
					<ClassTile
						key={index}
						cover={item.cover?.url || false}
						name={item.name}
						objective={item.objective}
						author={item.instructor?.name}
						id={item.id}
						nameFont={item.nameFont}
						onClick={() => navigate(`/class/${item.id}`)}
						// bottomTitle={bottomTitle}
						// playButton={playButton}
						noMargin={true}
					/>
				))}
		</div>
	);
}
