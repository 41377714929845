import React from "react";
import { classNames } from "../../helpers/common";
import { useFetch } from "../../services/classes";

export default function Categories({activeCategory}) {
	const { data: categories, loading, error } = useFetch(`categories`);


	const CategoryIem = ({ name, link }) => {
		return (
			<li key={name} className={classNames("col-span-1 flex")}>
				<div className="flex-1 flex items-center justify-between truncate">
					<a
						className={classNames("flex-1 flex items-center py-2 text-md  text-white hover:text-primary", activeCategory == name ? "!text-primary" : "")}
						href={link}
					>
						<span className="flex-1 ">{name}</span>
					</a>
				</div>
			</li>
		);
	};

	return (
		<ul role="list" className="grid grid-cols-1">
			<CategoryIem name="All Classes" link="/explore" />
			{categories &&
				categories.map((category) => (
					<CategoryIem
						name={category.name}
						link={`/explore/${category.name}`}
						key={category.id}
					/>
				))}
		</ul>
	);
}
